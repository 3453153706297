import ForgotPasswordForm from 'components/ForgotPasswordForm';
import PatternBackground from 'components/PatternBackground';
import React from 'react';

const ForgotPassword: React.FC = () => {
	return (
		<>
			<PatternBackground>
				<ForgotPasswordForm />
			</PatternBackground>
		</>
	);
};

export default ForgotPassword;
