import { ReactComponent as BanIcon } from 'static/images/ban.svg';
import { ReactComponent as ProductIcon } from 'static/images/boxes.svg';
import { ReactComponent as InReserveIcon } from 'static/images/calendar-clock-2.svg';
import { ReactComponent as TotalPriceIcon } from 'static/images/coins-2.svg';
import { ReactComponent as CrownIcon } from 'static/images/crown.svg';
import { ReactComponent as Deviation } from 'static/images/deviation.svg';
import { ReactComponent as Discount } from 'static/images/discount.svg';
import { ReactComponent as DiscountSquareIcon } from 'static/images/discount-square.svg';
import { ReactComponent as FinalCostIcon } from 'static/images/final-cost.svg';
import { ReactComponent as UuidIcon } from 'static/images/finger-print.svg';
import { ReactComponent as AvailableBalance } from 'static/images/free-remnant.svg';
import { ReactComponent as GroupPriceIcon } from 'static/images/group.svg';
import { ReactComponent as HandsCoinIcon } from 'static/images/hands-coin.svg';
import { ReactComponent as PriceIcon } from 'static/images/price-2.svg';
import { ReactComponent as RemainingIcon } from 'static/images/remaining.svg';
import { ReactComponent as MeasureUnitIcon } from 'static/images/rulers.svg';
import { ReactComponent as SavingsIcon } from 'static/images/savings.svg';
import { ReactComponent as QuantityIcon } from 'static/images/stack.svg';
import { ReactComponent as BrandIcon } from 'static/images/title.svg';
import { ReactComponent as MaxPriceIcon } from 'static/images/trend-down.svg';
import { ReactComponent as MinPriceIcon } from 'static/images/trend-up.svg';
import { ReactComponent as WalletIcon } from 'static/images/wallet.svg';

export const adjustableColumnsIcons = {
	Uuid: UuidIcon,
	Product: ProductIcon,
	Brand: BrandIcon,
	Quantity: QuantityIcon,
	MeasureUnit: MeasureUnitIcon,
	Price: PriceIcon,
	FinalCost: FinalCostIcon,
	TotalPrice: TotalPriceIcon,
	InReserve: InReserveIcon,
	Remaining: RemainingIcon,
	Group: GroupPriceIcon,
	Entry: MinPriceIcon,
	Min: MaxPriceIcon,
	AvailableBalance: AvailableBalance,
	Deviation: Deviation,
	ManualDiscount: Discount,
	LastPrice: BanIcon,
	FavorablePrice: CrownIcon,
	PromotionPrice: DiscountSquareIcon,
	Profitability: WalletIcon,
	DiyPrice: HandsCoinIcon,
	Points: SavingsIcon,
};
