import AlertDialogue from 'components/AlertDialogue';
import AlertDialogueContent from 'components/AlertDialogue/components/AlertDialogueContent';
import AlertDialogueControlButton from 'components/AlertDialogue/components/AlertDialogueControlButton';
import AlertDialogueFooter from 'components/AlertDialogue/components/AlertDialogueFooter';
import AlertDialogueHeader from 'components/AlertDialogue/components/AlertDialogueHeader';
import AlertDialogueMessage from 'components/AlertDialogue/components/AlertDialogueMessage';
import NewPasswordForm from 'components/NewPasswordForm';
import Spinner from 'components/Spinner';
import { ROUTES_URLS } from 'const';
import { useAuth } from 'hooks/useAuth';
import { useBoolean } from 'hooks/useBoolean';
import type { RestorePasswordDTO } from 'models/auth';
import React, { useCallback } from 'react';
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { windowTabManager } from 'services/windowTabManager';
import { useRestorePasswordMutation } from 'store/reducers/auth/authSliceApi';
import { logger } from 'utils/logger';
import { prepareUrl } from 'utils/shared';

import styles from './styles.module.css';

const ResetPassword: React.FC = () => {
	const { token } = useParams();
	const { whoami, login, isLoading } = useAuth();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [restorePasswordAsync, restorePasswordRequest] = useRestorePasswordMutation();
	const resetPasswordAlert = useBoolean();

	const email = searchParams.get('email');

	const onSubmit = useCallback(
		async (values: { confirm?: string; password?: string }) => {
			try {
				const dto: RestorePasswordDTO = {
					email,
					token,
					password: values.password,
					password_confirmation: values.confirm,
				};

				await restorePasswordAsync(dto).unwrap();

				try {
					await whoami();
					navigate(ROUTES_URLS.HOME);
					windowTabManager.emit('loggedIn');
				} catch {
					await login({ email, password: dto.password });
					navigate(ROUTES_URLS.HOME);
				}
			} catch (err) {
				logger.error(err);
				resetPasswordAlert.open();
			}
		},
		[token, email, whoami, login],
	);

	const handleRedirectToLogin = () => {
		navigate(prepareUrl(ROUTES_URLS.FORGOT_PASSWORD), {
			state: {
				email,
			},
		});
	};

	const showSpinner = isLoading || restorePasswordRequest.isLoading;

	return (
		<div className={styles.wrapper}>
			<NewPasswordForm onSubmit={onSubmit} />

			<Link to={prepareUrl(ROUTES_URLS.SIGN_IN)} className={`text-sm-medium color-primary-700 ${styles.backLink}`}>
				На сторінку авторизації
			</Link>

			{resetPasswordAlert.isOn && (
				<AlertDialogue onOutsideClick={resetPasswordAlert.close}>
					<AlertDialogueHeader onClose={resetPasswordAlert.close}>Протерміноване посилання</AlertDialogueHeader>
					<AlertDialogueContent>
						<AlertDialogueMessage>Здається, посилання, яке ви отримали в листі вже більше не дійсне.</AlertDialogueMessage>
						<AlertDialogueMessage>Будь ласка, повторіть процедуру відновлення пароля ще раз.</AlertDialogueMessage>
					</AlertDialogueContent>

					<AlertDialogueFooter>
						<AlertDialogueControlButton onClick={resetPasswordAlert.close} variant="cancel">
							Скасувати
						</AlertDialogueControlButton>
						<AlertDialogueControlButton onClick={handleRedirectToLogin} variant="submit">
							Відновити
						</AlertDialogueControlButton>
					</AlertDialogueFooter>
				</AlertDialogue>
			)}

			{showSpinner && <Spinner />}
		</div>
	);
};

export default ResetPassword;
