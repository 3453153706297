import React from 'react';
import { useSearchParams } from 'react-router-dom';

interface PageBasedIndexTableCellProps {
	index: number;
}

const PageBasedIndexTableCell: React.FC<PageBasedIndexTableCellProps> = ({ index }) => {
	const [searchParams] = useSearchParams();

	const page = Number(searchParams.get('page') || 1);
	const perPage = Number(searchParams.get('perPage') || 12);
	const rowNumber = index + 1 + (page - 1) * perPage;

	return <>{rowNumber}</>;
};

export default PageBasedIndexTableCell;
