import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import localforage from 'localforage';
import { RefreshResult, StoreToken } from 'models/auth';
import { toast } from 'react-toastify';
import { store } from 'store';

import { authActions } from '.';

const capitalize = (str: string) => {
	if (!str) return '';

	const isAllCapital = str.toUpperCase() === str;

	if (isAllCapital) return str;

	return str[0].toUpperCase() + str.slice(1);
};

export const baseApiUrl = 'https://testback-unit.alterego.biz.ua/api/v1/';
const apiClient = axios.create({
	baseURL: baseApiUrl,
	headers: {
		'Content-Type': 'application/json',
	},
});

interface AxiosRequestConfigWithRetry extends InternalAxiosRequestConfig {
	_retry?: boolean;
}

let refreshPromise: Promise<void> | null = null;
let failedRequestsQueue: Array<{
	onSuccess: VoidCallback;
	onError: (error: AxiosError) => void;
}> = [];

export const setupInterceptorsTo = (axiosInstance: AxiosInstance) => {
	const onRequest = async (config: AxiosRequestConfigWithRetry): Promise<AxiosRequestConfigWithRetry> => {
		const authTokens = await localforage.getItem<StoreToken>('auth-tokens');
		if (!authTokens) return config;

		const { accessToken, tokenType } = authTokens;
		config.headers['Authorization'] = `${tokenType} ${accessToken}`;

		return config;
	};

	const onRequestError = (error: AxiosError): Promise<AxiosError> => {
		return Promise.reject(error);
	};

	const onResponse = (response: AxiosResponse): AxiosResponse => {
		return response;
	};

	const onResponseError = async (error: AxiosError): Promise<AnyArg> => {
		const config = error.config as AxiosRequestConfigWithRetry;

		if (error.response && error.response.status === 401 && !config._retry) {
			if (!refreshPromise) {
				refreshPromise = new Promise(async (resolve, reject) => {
					try {
						const authTokens = await localforage.getItem<StoreToken>('auth-tokens');

						if (!authTokens) throw new Error('No auth tokens');

						const { refreshToken: oldRefreshToken, accessToken: oldAccessToken, tokenType } = authTokens;

						const response = await axios.post<RefreshResult>(
							`${baseApiUrl}/auth/refresh`,
							{ refresh_token: oldRefreshToken },
							{
								headers: {
									Authorization: `${tokenType} ${oldAccessToken}`,
								},
							},
						);

						const { access_token: newAccessToken, refresh_token: newRefreshToken, token_type: newTokenType } = response.data;
						const normalizedTokenType = capitalize(newTokenType);

						await localforage.setItem<StoreToken>('auth-tokens', {
							accessToken: newAccessToken,
							refreshToken: newRefreshToken,
							tokenType: normalizedTokenType,
						});

						// Process all queued requests with the new token
						failedRequestsQueue.forEach(({ onSuccess }) => onSuccess());
						failedRequestsQueue = [];

						resolve();
					} catch (err) {
						failedRequestsQueue.forEach(({ onError }) => onError(err as AxiosError));
						failedRequestsQueue = [];

						await localforage.removeItem('auth-tokens');

						toast.error('Час сесії сплив! Будь ласка, авторизуйтеся знову.');
						store.dispatch(authActions.clearLoggedInUser());

						reject(err);
					} finally {
						refreshPromise = null;
					}
				});
			}

			return new Promise((resolve, reject) => {
				failedRequestsQueue.push({
					onSuccess: async () => {
						config._retry = true;
						const tokens = await localforage.getItem<StoreToken>('auth-tokens');
						if (tokens) {
							config.headers['Authorization'] = `${tokens.tokenType} ${tokens.accessToken}`;
						}
						resolve(axiosInstance.request(config));
					},
					onError: (err: AxiosError) => reject(err),
				});
			});
		}

		return Promise.reject(error);
	};

	axiosInstance.interceptors.response.use(onResponse, onResponseError);
	axiosInstance.interceptors.request.use(onRequest, onRequestError);
};

setupInterceptorsTo(apiClient);

export default apiClient;
