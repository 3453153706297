export const sharedErrorMessages = {
	email: {
		required: 'E-mail адреса обовʼязкова',
		notValid: 'E-mail адреса не валідна',
	},
	name: {
		required: 'Імʼя обовʼязкове',
		notValid: 'Імʼя може містити лише літери та пробіли',
	},
	password: {
		required: 'Пароль обовʼязковий',
		notMatch: 'Паролі не співпадають',
		min: (min: string | number) => `Пароль має бути ${min} або більше символів`,
	},
	phone: {
		required: 'Телефон обовʼязковий',
		notValid: 'Телефон не валідний',
	},
	address: {
		required: 'Адреса є обовʼязковою',
	},
	image: {
		required: 'Зображення є обовʼязковим',
	},
	optionShape: {
		required: 'Поле обовʼязкове',
	},
	numberType: {
		required: 'Поле обовʼязкове',
		errorType: 'Має бути числове значення',
		min: (min: string | number) => `Не може бути менше ${min} цифр`,
		max: (max: string | number) => `Не може бути більше ${max} цифр`,
	},
	commercialProposal: {
		required: 'Назва комерційної пропозиції є обовʼязковою',
	},
} as const;
