import LoginForm from 'components/LoginForm';
import React from 'react';
import Blur from 'static/images/blur.svg';
import Logo from 'static/images/MainLogo.svg';
import Vector from 'static/images/Vector.svg';
import VectorBottom from 'static/images/Vector2.svg';

import styles from './style.module.css';

const Auth: React.FC = () => {
	return (
		<div className={styles.loginWrapper}>
			<a href="#">
				<img src={Logo} style={{ width: '86px' }} />
			</a>
			<div className={styles.top} style={{ backgroundImage: `url(${Vector})` }}>
				<span className={styles.elipceBlurTop} style={{ backgroundImage: `url(${Blur})` }}></span>
			</div>
			<LoginForm />
			<div className={styles.bottom} style={{ backgroundImage: `url(${VectorBottom})` }}>
				<span className={styles.elipceBlurBottom} style={{ backgroundImage: `url(${Blur})` }}></span>
			</div>
		</div>
	);
};

export default Auth;
