import React, { PropsWithChildren } from 'react';
import Blur from 'static/images/blur.svg';
import Vector from 'static/images/Vector.svg';
import VectorBottom from 'static/images/Vector2.svg';

import styles from './style.module.css';

const PatternBackground: React.FC<PropsWithChildren> = ({ children }) => {
	return (
		<div className={styles.loginWrapper}>
			<div className={styles.top} style={{ backgroundImage: `url(${Vector})` }}>
				<span className={styles.elipceBlurTop} style={{ backgroundImage: `url(${Blur})` }}></span>
			</div>
			{children}
			<div className={styles.bottom} style={{ backgroundImage: `url(${VectorBottom})` }}>
				<span className={styles.elipceBlurBottom} style={{ backgroundImage: `url(${Blur})` }}></span>
			</div>
		</div>
	);
};

export default PatternBackground;
