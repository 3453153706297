import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { OriginalPaginatedRequestResult, RequestResult } from 'models/api';
import { transformToPaginatedRequestResult } from 'models/api/transform';
import type { ClientOption, ClientPreview } from 'models/client';
import { Client, ClientOptionSchema } from 'models/client';
import { ClientFilters, ClientFiltersSchema } from 'models/client/filters';
import { Segment, SegmentSchema } from 'models/client/segment';
import { Option } from 'models/common/options';
import { ClientId } from 'models/common/uuid';
import { Contract, ContractSchema } from 'models/contract';
import { clientsQueryKeys } from 'services/queryKeys';
import { throwAnException } from 'utils/api/errors';
import { logger } from 'utils/logger';

import apiClient, { baseApiUrl } from '../auth/apiClient';

export const clientsSliceApi = createApi({
	reducerPath: 'clients',
	baseQuery: fetchBaseQuery({ baseUrl: baseApiUrl }),
	tagTypes: [clientsQueryKeys.clients(), 'filters'],
	endpoints: (builder) => ({
		createClient: builder.mutation<AnyArg, AnyArg>({
			queryFn: async (data) => {
				try {
					const response = await apiClient.post('/clients', data);

					return response.data;
				} catch (error) {
					throwAnException(error);
				}
			},
			invalidatesTags: [clientsQueryKeys.clients()],
		}),
		getCreateClientColloquialData: builder.query<AnyArg, void>({
			queryFn: async () => {
				try {
					const response = await apiClient.get('/clients/create');

					return response.data;
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		/**
		 * Due to error below we cannot use generics for builder.query<TData, TAgr>
		 * Type instantiation is excessively deep and possibly infinite.ts(2589)
		 */
		getClients: builder.query({
			queryFn: async (payload: string | string[]) => {
				try {
					const queryParams = Array.isArray(payload) ? payload[0] : payload;
					const response = await apiClient.get<OriginalPaginatedRequestResult<ClientPreview[]>>('/clients', {
						params: new URLSearchParams(queryParams),
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const result = transformToPaginatedRequestResult<ClientPreview[]>(response.data);

					return {
						data: {
							data: result.data,
							page: result.page,
							pagesCount: result.pagesCount,
							total: result.total,
						},
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			providesTags: (result) => (result ? [clientsQueryKeys.clients(), ...result.data.map(({ id }) => clientsQueryKeys.client(id))] : []),
		}),
		getSegments: builder.query<Segment[], string>({
			queryFn: async (queryParams) => {
				try {
					const response = await apiClient.get<Segment[]>('/segments', {
						params: new URLSearchParams(queryParams),
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const validation = SegmentSchema.array().safeParse(response.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return { data: [] };
					}

					return {
						data: validation.data,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		getClientsOptions: builder.query<Option[], string>({
			queryFn: async (query) => {
				try {
					const response = await apiClient.get<RequestResult<ClientOption[]>>('/clients/all' + '?search=' + query);

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const validation = ClientOptionSchema.array().safeParse(response.data.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return { data: [] };
					}

					return {
						data: validation.data as Option[],
					};
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		/**
		 * Due to error below we cannot use generics for builder.query<TData, TAgr>
		 * Type instantiation is excessively deep and possibly infinite.ts(2589)
		 */
		getClientById: builder.query({
			queryFn: async (id: ClientId) => {
				try {
					const response = await apiClient.get<RequestResult<Client>>(`/clients/${id}`);

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return {
						data: response.data.data as Client,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			providesTags: (_, __, id) => [clientsQueryKeys.client(id)],
		}),
		getContractList: builder.query<Contract[], string>({
			queryFn: async (clientId) => {
				try {
					const response = await apiClient.get<RequestResult<Contract[]>>(`/contracts/client/${clientId}`);

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}
					const validation = ContractSchema.array().safeParse(response.data.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: [],
						};
					}
					return {
						data: validation.data,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
		}),
		getClientsFiltersData: builder.query<ClientFilters, void>({
			queryFn: async () => {
				try {
					const response = await apiClient.get<ClientFilters>('/clients/filters');

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}
					const validation = ClientFiltersSchema.safeParse(response.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return { data: {} };
					}
					return {
						data: validation.data,
					};
				} catch (error) {
					throwAnException(error);
				}
			},
			providesTags: (result) => (result ? [clientsQueryKeys.clients(), 'filters'] : []),
		}),
	}),
});

export const {
	useGetClientsQuery,
	useGetClientByIdQuery,
	useGetSegmentsQuery,
	useGetClientsOptionsQuery,
	useLazyGetClientsOptionsQuery,
	useCreateClientMutation,
	useGetContractListQuery,
	useGetClientsFiltersDataQuery,
	useLazyGetClientsQuery,
	useLazyGetClientByIdQuery,
	useGetCreateClientColloquialDataQuery,
} = clientsSliceApi;
