import { ROUTES_URLS } from 'const';
import Auth from 'pages/Auth';
import ForgotPassword from 'pages/ForgotPassword';
import ResetPassword from 'pages/ResetPassword';
import React, { lazy } from 'react';

import ResetPasswordGuard from './components/ResetPasswordGuard';
import { isInviteLinkValid } from './handlers/auth';

const AuthInvitationLink = lazy(() => import('pages/AuthInvitationLink'));

export const AuthRoutes = [
	{
		path: ROUTES_URLS.SIGN_IN,
		element: <Auth />,
	},
	{
		path: ROUTES_URLS.FORGOT_PASSWORD,
		element: <ForgotPassword />,
	},
	{
		path: ROUTES_URLS.AUTH_VIA_INVITATION_LINK,
		loader: isInviteLinkValid,
		element: <AuthInvitationLink />,
	},
	{
		path: ROUTES_URLS.RESET_PASSWORD,
		element: (
			<ResetPasswordGuard>
				<ResetPassword />
			</ResetPasswordGuard>
		),
	},
];
