import { ROUTES_URLS } from 'const';
import { useAppSelector } from 'hooks/redux';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { authSelectors } from 'store/reducers/auth/selectors';

const RestrictedRoute: React.FC = () => {
	const me = useAppSelector(authSelectors.selectWhoamI);

	const renderOutlet = !me;

	return renderOutlet ? <Outlet /> : <Navigate replace to={ROUTES_URLS.HOME} />;
};

export default RestrictedRoute;
