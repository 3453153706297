import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AxiosError } from 'axios';
import type { LoginDTO, LoginResult, RestorePasswordDTO, Whoami } from 'models/auth';

import { authActions } from '.';
import apiClient from './apiClient';

export const authSliceApi = createApi({
	reducerPath: 'authApi',
	baseQuery: fetchBaseQuery({ baseUrl: '/auth' }),
	endpoints: (builder) => ({
		login: builder.mutation<LoginResult, LoginDTO>({
			queryFn: async (credentials: LoginDTO) => {
				try {
					const response = await apiClient.post<LoginResult>('/auth/sign_in', credentials);
					return {
						data: response.data,
					};
				} catch (error) {
					if (error instanceof AxiosError) {
						throw new Error(error.response.data.message);
					}
				}
			},
		}),
		getMe: builder.query<Whoami, void>({
			queryFn: async () => {
				try {
					const response = await apiClient.get<Whoami>('/auth/me');

					return {
						data: response.data,
					};
				} catch (error) {
					if (error instanceof AxiosError) {
						throw new Error(error.response.data.message);
					}
				}
			},
		}),
		logout: builder.mutation<void, void>({
			queryFn: async (_, { dispatch }) => {
				try {
					await apiClient.post<void>('/auth/logout');
					return {
						data: undefined,
					};
				} catch (error) {
					dispatch(authActions.clearLoggedInUser());
				}
			},
		}),
		resetPassword: builder.mutation<void, Pick<LoginDTO, 'email'>>({
			queryFn: async (dto) => {
				try {
					await apiClient.post<LoginResult>('/auth/password/email', dto);

					return {
						data: undefined,
					};
				} catch (error) {
					if (error instanceof AxiosError) {
						throw new Error(error.response.data.message);
					}
				}
			},
		}),
		restorePassword: builder.mutation<void, RestorePasswordDTO>({
			queryFn: async (dto) => {
				try {
					await apiClient.post<LoginResult>('/auth/password/reset', dto);

					return {
						data: undefined,
					};
				} catch (error) {
					if (error instanceof AxiosError) {
						throw new Error(error.response.data.error);
					}
				}
			},
		}),
	}),
});

export const { useLoginMutation, useGetMeQuery, useLogoutMutation, useLazyGetMeQuery, useResetPasswordMutation, useRestorePasswordMutation } =
	authSliceApi;
