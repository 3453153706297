import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type { OriginalPaginatedRequestResult, PaginatedRequestResult, RequestResult } from 'models/api';
import { transformToPaginatedRequestResult } from 'models/api/transform';
import type { Option } from 'models/common/options';
import {
	Employee,
	EmployeeOptionSchema,
	EmployeePreview,
	EmployeePreviewSchema,
	OrganizationOptionSchema,
	ServerSideEmployeeOption,
	ServerSideOrganizationOption,
} from 'models/employee';
import type { IEmployee, IEmployeeCreateDTO, IEmployeePathDTO } from 'models/IUser';
import { Stock, StockOptionSchema } from 'models/stock';
import { usersQueryKeys } from 'services/queryKeys';
import { logger } from 'utils/logger';

import apiClient, { baseApiUrl } from '../auth/apiClient';

export const usersSliceApi = createApi({
	reducerPath: 'users',
	baseQuery: fetchBaseQuery({ baseUrl: baseApiUrl }),
	tagTypes: [usersQueryKeys.users()],
	endpoints: (builder) => ({
		getEmployees: builder.query<PaginatedRequestResult<EmployeePreview[]>, string>({
			queryFn: async (queryParams) => {
				try {
					const response = await apiClient.get<OriginalPaginatedRequestResult<EmployeePreview[]>>('/users', {
						params: new URLSearchParams(queryParams),
					});

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const result = transformToPaginatedRequestResult<EmployeePreview[]>(response.data);
					const validation = EmployeePreviewSchema.array().safeParse(result.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: {
								data: [],
								page: 1,
								pagesCount: 1,
							},
						};
					}

					return {
						data: {
							data: validation.data,
							page: result.page,
							pagesCount: result.pagesCount,
						},
					};
				} catch (error) {
					throw error;
				}
			},
			providesTags: (result) =>
				result?.data ? [usersQueryKeys.users(), usersQueryKeys.usersOnly(), ...result.data.map(({ id }) => usersQueryKeys.user(id))] : [],
		}),
		getEmployeeById: builder.query<Employee, string>({
			queryFn: async (id) => {
				try {
					const response = await apiClient.get<RequestResult<Employee>>(`/users/${id}`);

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
			providesTags: (_, __, id) => [usersQueryKeys.user(id)],
		}),
		createEmployee: builder.mutation<IEmployee, IEmployeeCreateDTO>({
			queryFn: async (data) => {
				try {
					const response = await apiClient.post('/users', data);

					if (response.status !== 201) {
						throw new Error(response.statusText);
					}

					return response.data;
				} catch (error) {
					throw error;
				}
			},
			invalidatesTags: [usersQueryKeys.usersOnly()],
		}),
		updateEmployee: builder.mutation<IEmployee, IEmployeePathDTO>({
			query: (employee) => ({
				url: `/users/${employee.id}`,
				method: 'PUT',
				body: employee,
			}),
			invalidatesTags: [usersQueryKeys.usersOnly()],
			async onQueryStarted(employee, { dispatch, queryFulfilled }) {
				const optimisticOrderUpdate = dispatch(
					usersSliceApi.util.updateQueryData('getEmployeeById', String(employee.id), (draft) => {
						Object.assign(draft ?? {}, employee);
					}),
				);
				try {
					await queryFulfilled;
				} catch {
					optimisticOrderUpdate.undo();
				}
			},
		}),
		getWarehouses: builder.query<Option[], void>({
			queryFn: async () => {
				try {
					const response = await apiClient.get<Stock[]>('/stocks');

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}
					const validation = StockOptionSchema.array().safeParse(response.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return { data: [] };
					}

					return {
						data: validation.data as Option[],
					};
				} catch (error) {
					throw error;
				}
			},
		}),
		getEmployeesOptionList: builder.query<Option[], void>({
			queryFn: async () => {
				try {
					const response = await apiClient.get<RequestResult<ServerSideEmployeeOption[]>>('/users/all');

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const validation = EmployeeOptionSchema.array().safeParse(response.data.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: [],
						};
					}

					return {
						data: validation.data as Option[],
					};
				} catch (error) {
					throw error;
				}
			},
		}),
		getOrganizationsOptionList: builder.query<Option[], void>({
			queryFn: async () => {
				try {
					const response = await apiClient.get<RequestResult<ServerSideOrganizationOption[]>>('/organizations');

					if (response.status !== 200) {
						throw new Error(response.statusText);
					}

					const validation = OrganizationOptionSchema.array().safeParse(response.data.data);

					if (!validation.success) {
						logger.error(validation.error.errors);

						return {
							data: [],
						};
					}

					return {
						data: validation.data as Option[],
					};
				} catch (error) {
					throw error;
				}
			},
		}),
	}),
});

export const {
	useGetEmployeeByIdQuery,
	useGetEmployeesQuery,
	useUpdateEmployeeMutation,
	useGetWarehousesQuery,
	useCreateEmployeeMutation,
	useGetEmployeesOptionListQuery,
	useGetOrganizationsOptionListQuery,
} = usersSliceApi;
