import { OptionSchema, ServerSideOptionSchema } from 'models/common/options';
import { PhoneSchema } from 'models/common/phones';
import { standardizeOption } from 'models/common/preprocess';
import { ClientIdSchema, ClientManagerIdSchema, SegmentIdSchema } from 'models/common/uuid';
import { ContractSchema } from 'models/contract';
import { z } from 'zod';

import { IndividualSegmentSchema, LegalSegmentSchema, SegmentSchema } from './segment';
import { withStandardizedName } from './utils';

export const ClientNameSchema = z.object({ name: z.string() });
export const ClientOptionSchema = standardizeOption<typeof OptionSchema, { id: string; name: string }>(OptionSchema, ({ id, name }) => ({
	label: name,
	value: id,
}));

export const ClientManagerSchema = z.object({
	id: ClientManagerIdSchema,
	'1c_uuid': z.string().min(1),
	name: z.string().min(1),
});
export const ClientPaymentStatisticSchema = z.object({
	availableSum: z.number(),
	limit: z.number(),
	debt: z.number(),
});
export const ClientPaymentDetailSchema = z.object({
	sum: z.number(),
	amount: z.number(),
	fromAmount: z.number(),
});
export const ClientStatusSchema = z.union([z.literal('green'), z.literal('white'), z.literal('yellow'), z.literal('red')]);

export const BaseClientSchema = z.object({
	id: ClientIdSchema,
	email: z.string().nullable(),
	code: z.string().nullish(),
	avatar: z.string().nullish(),
});

export const OrderClientSchema = BaseClientSchema.extend({ name: z.string() });
export const ContractClientSchema = BaseClientSchema;
export const ContractOptionSchema = standardizeOption<typeof OptionSchema, { title: string; id: string }>(OptionSchema, ({ title, id }) => ({
	value: id,
	label: title,
}));

export const BaseIndividualClientSchema = z.object({
	segment: IndividualSegmentSchema.nullable(),
	ipnCode: z.string(),
});
export const BaseLegalClientSchema = z.object({
	segment: LegalSegmentSchema.nullable(),
	edrpouCode: z.string(),
	ipnCode: z.string().optional().nullable(),
	name: z.string().optional().nullable(),
	organizationName: z.string().optional().nullable(),
});

export const BaseClientPreviewSchema = BaseClientSchema.extend({
	createdAt: z.string().nullable(),
	lastDeal: z.string().nullable(),
	ordersCount: z.coerce.number(),
	segment: SegmentSchema.nullable(),
	manager: ClientManagerSchema.nullable(),
	activities: z.array(ServerSideOptionSchema),
	limit: ClientPaymentStatisticSchema.or(z.number()).optional(),
	clientStatus: z.number().optional(),
	comment: z.string().nullish(),
});

// Preview schemas are used in lists
export const IndividualClientPreviewSchema = withStandardizedName(
	BaseClientPreviewSchema.omit({ segment: true }).merge(BaseIndividualClientSchema).merge(ClientNameSchema),
);
export const LegalClientPreviewSchema = withStandardizedName(
	BaseClientPreviewSchema.omit({ segment: true }).merge(BaseLegalClientSchema).merge(ClientNameSchema),
);
export const ClientPreviewSchema = IndividualClientPreviewSchema.or(LegalClientPreviewSchema);

const BaseCreateClientSchema = BaseClientSchema.omit({ id: true, code: true }).extend({
	phones: z.array(PhoneSchema),
	passport: z.string().nullish(),
	address: z.string().nullish(),
	segmentId: SegmentIdSchema,
	managerId: z.string(),
	comment: z.string().nullish(),
	activities: z.array(z.string()),
});
export const CreateIndividualClientSchema = BaseCreateClientSchema.merge(BaseIndividualClientSchema);
export const CreateLegalClientSchema = BaseCreateClientSchema.merge(BaseLegalClientSchema);
export const CreateClientSchema = CreateIndividualClientSchema.or(CreateLegalClientSchema);

export const UpdateClientSchema = CreateClientSchema;

const BaseDetailedClientSchema = BaseCreateClientSchema.omit({
	segmentId: true,
	managerId: true,
	email: true,
	phones: true,
}).extend({
	manager: z
		.object({
			'1c_uuid': z.string().optional(),
			id: z.number(),
			name: z.string(),
			email: z.string().email(),
			createdAt: z.coerce.date(),
			stock: z.object({ title: z.string(), id: z.string() }).nullable(),
			department: z.object({ title: z.string(), id: z.string() }).nullable(),
		})
		.nullable(),
	id: ClientIdSchema,
	email: z.string().optional().nullable(),
	segment: SegmentSchema.nullable().nullable(),
	phones: z.array(PhoneSchema).optional(),
	contracts: z.array(ContractSchema.omit({ expiredAt: true })),
	activities: z.array(ServerSideOptionSchema),
	createdAt: z.coerce.date(),
	daysOfDelay: z.number(),
	lastDeal: z.string().nullable(),
	ordersCount: z.number(),
	status: ClientStatusSchema.optional().nullable(),
	firstRealization: z.string().optional().nullable(),
	totalSum: z.number().optional().nullable(),
	available: ClientPaymentStatisticSchema.optional().nullable(),
	waitingForPayment: ClientPaymentDetailSchema.optional().nullable(),
	prepaidPayments: ClientPaymentDetailSchema.optional().nullable(),
	overduePayments: ClientPaymentDetailSchema.optional().nullable(),
});

export const IndividualClientSchema = BaseIndividualClientSchema.merge(BaseDetailedClientSchema.omit({ segment: true })).merge(ClientNameSchema);
export const LegalClientSchema = BaseLegalClientSchema.merge(BaseDetailedClientSchema.omit({ segment: true })).merge(ClientNameSchema);
export const ClientSchema = withStandardizedName(IndividualClientSchema.merge(ClientNameSchema).or(LegalClientSchema.merge(ClientNameSchema)));

export type ClientPreview = z.infer<typeof ClientPreviewSchema>;
export type Client = z.infer<typeof ClientSchema>;
export type IndividualClient = z.infer<typeof IndividualClientSchema>;
export type LegalClient = z.infer<typeof LegalClientSchema>;
export type ClientOptions = z.infer<typeof ClientOptionSchema>;
export type CreateClient = z.infer<typeof CreateClientSchema>;
export type UpdateClient = z.infer<typeof UpdateClientSchema>;
export type ClientOption = z.infer<typeof ClientOptionSchema>;
