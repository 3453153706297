import { ROUTES_URLS } from 'const';
import React from 'react';
import { Navigate, useParams } from 'react-router-dom';

const ResetPasswordGuard: React.FC<React.PropsWithChildren> = ({ children }) => {
	const { token } = useParams();

	if (!token) {
		return <Navigate replace to={ROUTES_URLS.SIGN_IN} />;
	}

	return children;
};

export default ResetPasswordGuard;
