import 'static/styles/style.css';

import React from 'react';

import styles from './style.module.css';
import { IProps } from './types';

const CheckboxBase: React.FC<IProps> = ({ label, checked, setChecked }) => {
	return (
		<div className={styles.wrapper}>
			<input type="checkbox" checked={checked} onChange={(e) => setChecked(e.target.checked)} className={styles.checkbox} />
			<label className={`${styles.label} text-sm-medium color-grey-700`}>{label}</label>
		</div>
	);
};

export default CheckboxBase;
