import Input from 'components/Input';
import type { IProps as InputProps } from 'components/Input/typed';
import React, { ForwardedRef, forwardRef, useState } from 'react';
import { ReactComponent as OpenEyeIcon } from 'static/images/eye.svg';
import { ReactComponent as ClosedEyeIcon } from 'static/images/eye-off.svg';

import styles from './styles.module.css';

type PasswordInputProps = Omit<InputProps, 'ref' | 'type'> & {};

const PasswordInput = ({ ...props }: PasswordInputProps, ref: ForwardedRef<HTMLInputElement>) => {
	const [type, setType] = useState<'password' | 'text'>('password');
	const isPassHidden = type === 'password';

	const toggleType = () => {
		if (type === 'password') {
			setType('text');
		} else {
			setType('password');
		}
	};

	return (
		<div className={styles.passwordInputWrapper}>
			<Input {...props} ref={ref} type={type} />

			<button type="button" className={styles.actionButton} onClick={toggleType}>
				{isPassHidden && <ClosedEyeIcon />}
				{!isPassHidden && <OpenEyeIcon />}
			</button>
		</div>
	);
};

export default forwardRef(PasswordInput);
