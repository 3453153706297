let broadcastChannel = null;

if ('BroadcastChannel' in window) {
	broadcastChannel = new BroadcastChannel('auth');
}

type EventName = 'loggedIn';

export const windowTabManager = {
	on: (eventName: EventName, cb: <TData>(data: TData) => void) => {
		if (!broadcastChannel) return;

		broadcastChannel.onmessage = (event) => {
			if (event.data?.type === eventName) {
				cb?.(event.data?.payload);
			}
		};
	},
	emit: (eventName: EventName, payload?: AnyArg) => {
		if (!broadcastChannel) return;

		broadcastChannel.postMessage({ type: eventName, payload });
	},
	unsubscribe: () => {
		if (!broadcastChannel) return;

		broadcastChannel.close();
	},
};
