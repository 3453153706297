import { zodResolver } from '@hookform/resolvers/zod';
import Button from 'components/Button';
import CheckboxBase from 'components/CheckboxBase';
import Input from 'components/Input';
import Spacer from 'components/Spacer';
import Spinner from 'components/Spinner';
import { useAuth } from 'hooks/useAuth';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import eye from 'static/images/eye.svg';
import eyeOff from 'static/images/eye-off.svg';
import { authEmail, passwordField } from 'validation/fields';
import { z } from 'zod';

import styles from './style.module.css';

enum SwitcherType {
	'text' = 'text',
	'password' = 'password',
}

const LoginSchema = z.object({
	email: authEmail,
	password: passwordField,
	remember: z.boolean().optional(),
});

type LoginCredentials = z.infer<typeof LoginSchema>;

const LoginForm: React.FC = () => {
	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<LoginCredentials>({
		resolver: zodResolver(LoginSchema),
		defaultValues: {
			email: '',
			password: '',
			remember: true,
		},
	});

	const auth = useAuth();
	const [type, setType] = useState<SwitcherType>(SwitcherType.password);
	const [eyeIcon, setEyeIcon] = useState(eyeOff);

	const handleShowOrHidePassword = () => {
		if (type === SwitcherType.text) {
			setType(SwitcherType.password);
			setEyeIcon(eyeOff);
		} else {
			setType(SwitcherType.text);
			setEyeIcon(eye);
		}
	};

	const handleLogin = async (credentials: LoginCredentials) => {
		await auth.login(credentials);
	};

	return (
		<form className={styles.formWrapper} onSubmit={handleSubmit(handleLogin)}>
			<p className="text-md-regular color-grey-600">Будь ласка, введіть свої дані щоб продовжити</p>
			<Spacer height={'32px'} />
			<Controller
				name="email"
				control={control}
				render={({ field }) => (
					<>
						<Input value={field.value} setValue={field.onChange} type="text" placeholder="Ваш email" label="Email" />
						{!!errors.email?.message && <p className="error-text">{errors.email?.message}</p>}
					</>
				)}
			/>
			<Spacer height={'20px'} />
			<Controller
				name="password"
				control={control}
				render={({ field }) => (
					<>
						<Input
							value={field.value}
							setValue={field.onChange}
							type={type}
							placeholder="••••••"
							label="Пароль"
							icon={eyeIcon}
							onClickIcon={handleShowOrHidePassword}
						/>
						{!!errors.password?.message && <p className="error-text">{errors.password?.message}</p>}
					</>
				)}
			/>
			<Spacer height={'24px'} />
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<Controller
					name="remember"
					control={control}
					render={({ field }) => <CheckboxBase label="Запам`ятати мене на 8 годин" checked={field.value} setChecked={field.onChange} />}
				/>
				<a href="/forgot-password" className={'text-sm-medium color-primary-700'}>
					Забули пароль?
				</a>
			</div>
			<Spacer height={'24px'} />
			<Button type="submit" text="Вхід до особистого кабінету" variant="default" />

			{auth.isLoading && <Spinner />}
		</form>
	);
};

export default LoginForm;
