import { useOrderCart } from 'hooks/useOrderCart';
import { useOrderOperationMethods } from 'pages/OrderRework/hooks/useOrderOperationMethods';
import React, { FunctionComponent } from 'react';
import { toast } from 'react-toastify';
import { formatInputValue } from 'utils/inputs';
import { toFixed } from 'utils/shared';

const productSuccessfullyAdded = () => toast.success('Товар додано до заявки');

export const withOneStepOrderCart = <TProps extends object>(Component: FunctionComponent<TProps>, { mode }: { mode: 'creation' | 'edit' }) => {
	const WrappedComponent = (props: Omit<TProps, 'mode' | 'onSave' | 'onRowClick' | 'isLoading'>) => {
		const cart = useOrderCart();
		const { addEntityToOrder } = useOrderOperationMethods();

		const onRowClick = async (rowIndex: number, quantity: number | string) => {
			const product = cart.getPreviewProduct();
			const isInt = !!product?.unit?.isInt;

			const valueFormatter = formatInputValue({ isInt });
			const amount = toFixed(valueFormatter(quantity), { isInt, precise: 2 });

			if (rowIndex === 0) {
				cart.updateProductQuantityInCart(amount);
			} else {
				const searchParams = new URLSearchParams(window.location.search);
				const addTo = Number(searchParams.get('from') || 0);
				addEntityToOrder({
					candidates: [
						{ ...product, amount, deviation: String(product.deviation ?? '0'), profitability: String(product.profitability ?? '0') },
					],
					entityName: 'products',
					to: addTo,
				});

				productSuccessfullyAdded();
				cart.closeModal();
				cart.clearMaybeSelectOne();
			}
		};

		return (
			<Component
				{...(props as TProps)}
				onRowClick={onRowClick}
				onSave={null}
				isLoading={cart.isLoading}
				mode={mode}
				onAddEntitySuccess={productSuccessfullyAdded}
			/>
		);
	};

	return WrappedComponent;
};
