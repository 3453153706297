import clsx from 'clsx';
import Spinner from 'components/Spinner';
import { useBoolean } from 'hooks/useBoolean';
import { useOrderRouter } from 'hooks/useOrderRouter';
import React, { lazy, Suspense } from 'react';
import Code from 'static/images/code.svg';
import Dataflow from 'static/images/dataflow.svg';

import { Can } from '../OrderAbility/provider';
import CustomButton from './CustomButton';
import styles from './style.module.css';

const Drawer = lazy(() => import('components/Drawer'));
const StructureDrawerComponent = lazy(() => import('../../../components/OrderPageComponents/StructureDrawerComponent/Index'));

const OrderActionButtons: React.FC<{ className?: string }> = ({ className }) => {
	const { isOn, toggle } = useBoolean();
	const router = useOrderRouter();

	return (
		<div data-order-action-buttons className={clsx(styles.buttonsWrapper, className)}>
			<Can passThrough I="split" an="order">
				{(can) => {
					return (
						<CustomButton
							title="Розбити"
							onClick={() => {
								if (!can) return;

								router.toSpitOrder();
							}}
							icon={Code}
							bgColor="var(--primary-50)"
							color="var(--primary-700)"
							hoverBg="var(--primary-100)"
							disabled={!can}
						/>
					);
				}}
			</Can>
			<Can passThrough I="click" an="order.structure">
				{(can) => {
					return (
						<CustomButton
							title="Структура"
							icon={Dataflow}
							bgColor="var(--primary-50)"
							color="var(--primary-700)"
							hoverBg="var(--primary-100)"
							onClick={toggle}
							disabled={!can}
						/>
					);
				}}
			</Can>

			<Suspense fallback={<Spinner />}>
				{isOn && (
					<Drawer open={isOn} title={'Структура'} onClose={toggle}>
						<StructureDrawerComponent />
					</Drawer>
				)}
			</Suspense>
		</div>
	);
};

export default OrderActionButtons;
