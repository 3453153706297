import { ProductIdSchema } from 'models/common/uuid';
import { PriceSchema } from 'models/price';
import { StockSchema } from 'models/stock';
import { z } from 'zod';

import { OrderProductSchema } from './product';

const CatalogueProductAvailabilitySchema = z.object({
	amount: z.number(),
	stock: StockSchema,
});

export const CatalogueProductSchema = OrderProductSchema.omit({
	sumTax: true,
	sum: true,
	amount: true,
	coefficient: true,
	leftovers: true,
	freeLeftovers: true,
	reserves: true,
	deviation: true,
	profitability: true,
	price: true,
}).extend({
	id: ProductIdSchema,
	leftovers: z.array(CatalogueProductAvailabilitySchema),
	freeLeftovers: z.array(CatalogueProductAvailabilitySchema),
	reserves: z.array(CatalogueProductAvailabilitySchema),
	deviation: z.string().optional(),
	profitability: z.string().optional(),
	category: z.any(),
	prices: PriceSchema.array(),
});

export type CatalogueProduct = z.infer<typeof CatalogueProductSchema>;
export type CatalogueProductWithAmount = CatalogueProduct & { amount: string; isBlank?: boolean };
